import React, { FC, useCallback, useState } from 'react'
import { Avatar, Heading, HStack, Icon, StackProps, Text, VStack } from '@chakra-ui/react'
import { FaMobile } from 'react-icons/fa'
import { BrokerDTO } from '@/core/broker/typings'
import { gtmEvent, RealestateDetailBrokerTelephoneClick } from '@/utils/gtm'
import { variantUrls } from '@/core/media/utils/variant-urls'

interface BrokerContactCardProps extends StackProps {
  broker: BrokerDTO
}

const BrokerContactCard: FC<BrokerContactCardProps> = ({ broker, ...props }) => {
  const [showPhone, setShowPhone] = useState(false)

  const handleReveal = useCallback(
    (prop: 'phone') => {
      if (prop === 'phone') {
        gtmEvent<RealestateDetailBrokerTelephoneClick>('realestate_detail_broker_telephone_click', {
          broker_id: broker.id
        })
        setShowPhone(true)
      }
    },
    [broker.id]
  )

  return (
    <HStack
      align="center"
      spacing={6}
      w="full"
      p={4}
      py={8}
      rounded="md"
      maxW="sm"
      bg="background.surface"
      {...props}>
      <VStack w="full" spacing={6} align="center">
        <Heading as="h5" fontSize="3xl">
          Contacto
        </Heading>
        <Avatar src={variantUrls(broker.img)['600x600']} h={'80px'} w={'80px'} />
        <Heading as="h6" fontSize="lg">
          {broker.name}
        </Heading>

        <HStack spacing={4}>
          <Icon as={FaMobile} boxSize={6} />
          {!showPhone ? (
            <Text
              flex={1}
              fontSize="md"
              cursor="pointer"
              color="blue.500"
              onClick={() => handleReveal('phone')}>
              Teléfono
            </Text>
          ) : (
            <Text
              as="a"
              href={`tel:${broker.phone}`}
              flex={1}
              fontSize="md"
              color="blue.500"
              cursor="pointer">
              {broker.phone}
            </Text>
          )}
        </HStack>
      </VStack>
    </HStack>
  )
}

export default BrokerContactCard
